import dayjs from 'dayjs';
import 'dayjs/locale/ja'; // load Japanese locale

dayjs.locale('ja');

export const formatDateTime = (datetime: string, formatOption = 'YYYY-MM-DD (ddd) HH:mm') => {
  return datetime && dayjs(datetime).format(formatOption);
};

export const formatDateTimeForEventStart = (datetime: string) => {
  return datetime && dayjs(datetime).format('YYYY-MM-DD (ddd) \n試合開始 HH:mm～');
};
