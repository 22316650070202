import { useWeb3Auth } from '@/services/web3Auth/Web3Auth';
import { ConnectButton, WalletButton } from '@rainbow-me/rainbowkit';
import { useEffect, useState } from 'react';
import { useAccount, useDisconnect } from 'wagmi';
import Overlay from '../layout/Overlay';
import { useAuthStore } from '@/recoil/authStore/useAuthStore';
import { TicketRepository } from '@/repositories/TicketRepository';
import classNames from 'classnames';
import { useQueryClient } from '@tanstack/react-query';
import { Button } from './Button';

type Props = {
  title: string;
  ticketId?: string;
  disabled?: boolean;
};

export function WalletConnectButton({ title, ticketId, disabled = false }: Props) {
  const queryClient = useQueryClient();

  const { address, isConnected } = useAccount();
  const { disconnectAsync } = useDisconnect();

  const { signWithWallet, handleNavigateUrl } = useWeb3Auth();
  const { user } = useAuthStore();

  const [isLoading, setIsLoading] = useState(false);
  const [isShowSignOverlay, setIsShowSignOverlay] = useState(false);

  const clearWagmiData = async () => {
    queryClient.clear();

    await disconnectAsync();

    await Promise.all([localStorage.removeItem('wagmi.cache'), localStorage.removeItem('wagmi.store')]);

    if (typeof window !== 'undefined') {
      await Promise.all([window.localStorage.removeItem('wagmi.cache'), window.localStorage.removeItem('wagmi.store')]);
    }
  };

  const handleCloseSignOverlay = async () => {
    setIsLoading(false);
    setIsShowSignOverlay(false);
    await clearWagmiData();
  };

  const onClickConnect = async (callback: () => void) => {
    if (disabled) return;

    ticketId && TicketRepository.saveToBrowser(ticketId);

    if (!user || !address || !isConnected) {
      try {
        setIsShowSignOverlay(true);
        callback();
      } catch (e) {
        console.error('err connect wallet', e);
      }
      return;
    }
    await handleNavigateUrl(user, false);
  };

  const onClickSign = () => {
    setIsLoading(true);
    signWithWallet().catch((e) => {
      console.error(e);
      setIsShowSignOverlay(false);
    });
  };

  useEffect(() => {
    console.log(window.ethereum);
  }, []);

  return (
    <div className="w-full flex flex-col gap-1 text-center">
      <ConnectButton.Custom>
        {({ account, chain, openConnectModal, authenticationStatus, mounted }) => {
          const ready = mounted && authenticationStatus !== 'loading';

          const connected =
            ready && account && chain && (!authenticationStatus || authenticationStatus === 'authenticated');
          return (
            <div
              className="w-full text-center"
              {...(!ready && {
                'aria-hidden': true,
                style: {
                  opacity: 0,
                  pointerEvents: 'none',
                  userSelect: 'none',
                },
              })}
            >
              {(() => {
                return (
                  <>
                    <span
                      className={classNames('text-primaryRed font-bold text-sm underline decoration-solid', {
                        '!text-gray1': disabled,
                      })}
                      onClick={() => onClickConnect(openConnectModal)}
                    >
                      {title}
                    </span>

                    {connected && isShowSignOverlay && (
                      <Overlay onClose={handleCloseSignOverlay}>
                        <div className="flex flex-col justify-center h-full">
                          <div className="bg-white rounded-wrapper flex flex-col px-4 py-8 gap-6 items-center text-center">
                            <div className="flex flex-col gap-6">
                              <p className="text-xl font-bold">
                                ウォレットの接続が完了しました。
                                <br />
                                次は署名してログインします
                              </p>
                            </div>

                            <Button isLoading={isLoading} onClick={onClickSign}>
                              Web3ウォレットで署名する
                            </Button>
                          </div>
                        </div>
                      </Overlay>
                    )}
                  </>
                );
              })()}
            </div>
          );
        }}
      </ConnectButton.Custom>
      <p className="text-[10px] leading-5">
        Web3ウォレットアプリと接続がうまくできない場合は、
        <br />
        ウォレット内ブラウザをご利用ください。
      </p>
    </div>
  );
}
