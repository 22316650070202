const ERROR_MESSAGES = {
  TICKETS_OUT_OF_STOCK_ERROR: 'チケットの配布上限に達しました。',
  TICKET_DISABLED_ERROR: 'このチケットは無効になりました。',
  USER_TICKET_ALREADY_CONSUMED: 'このチケットは利用しました。',
  USER_CERTIFICATE_ALREADY_EXISTS_ERROR: 'この紙チケットは既に読み取っています。',
  CERTIFICATE_NOT_FOUND_ERROR: '試合の日付はデジタル証明書の日付と異なっています。',
  CERTIFICATE_DISABLED_ERROR: 'このデジタル証明証は存在していません。',
};

export default ERROR_MESSAGES;
