import AndroidAppInstallIcon from './android-app-install-icon.svg';
import AppleShareIcon from './apple-share-icon.svg';
import CirclePlusIcon from './circle-plus-icon.svg';
import CloseIcon from './close-icon.svg';
import CopyIcon from './copy-icon.svg';
import HamburgerIcon from './hamburger-icon.svg';
import KebabIcon from './kebab-icon.svg';
import RedWarningIcon from './red-warning-icon.svg';
import SafariIcon from './safari-icon.svg';
import TriangleDownIcon from './triangle-down-icon.svg';
import CameraIcon from './camera-icon.svg';
import QrIcon from './qr-icon.svg';
import AchieveIcon from './achieve-icon.svg';
import ForwardArrowIcon from './forward-arrow-icon.svg';
import BearIcon from './bear-icon.svg';
import EllipsisIcon from './ellipsis-icon.svg';
import InfoIcon from './info-icon.svg';
import PhotoIcon from './photo-icon.svg';
import NoCertificateIcon from './no-certificate-icon.svg';
import Logo from './logo_asteeda.svg';
import CheckedCircleIcon from './checked-circle.svg';
import CancelCircleIcon from './cancel-circle.svg';
import Benefit1Icon from './benefit-1-icon.svg';
import Benefit2Icon from './benefit-2-icon.svg';
import Benefit3Icon from './benefit-3-icon.svg';
import Benefit4Icon from './benefit-4-icon.svg';
import Benefit5Icon from './benefit-5-icon.svg';
import Benefit6Icon from './benefit-6-icon.svg';
import Benefit7Icon from './benefit-7-icon.svg';
import Benefit8Icon from './benefit-8-icon.svg';
import BackIcon from './back-icon.svg';

import BulletNumber1 from './bullet-number-1.svg';
import BulletNumber2 from './bullet-number-2.svg';
import BulletNumber3 from './bullet-number-3.svg';
import BulletNumber4 from './bullet-number-4.svg';
import BulletNumber5 from './bullet-number-5.svg';

export {
  AndroidAppInstallIcon,
  AppleShareIcon,
  CirclePlusIcon,
  CloseIcon,
  CopyIcon,
  HamburgerIcon,
  KebabIcon,
  RedWarningIcon,
  SafariIcon,
  TriangleDownIcon,
  CameraIcon,
  QrIcon,
  AchieveIcon,
  ForwardArrowIcon,
  BearIcon,
  EllipsisIcon,
  InfoIcon,
  PhotoIcon,
  NoCertificateIcon,
  Logo,
  CheckedCircleIcon,
  CancelCircleIcon,
  Benefit1Icon,
  Benefit2Icon,
  Benefit3Icon,
  Benefit4Icon,
  Benefit5Icon,
  Benefit6Icon,
  Benefit7Icon,
  Benefit8Icon,
  BackIcon,
  BulletNumber1,
  BulletNumber2,
  BulletNumber3,
  BulletNumber4,
  BulletNumber5,
};
