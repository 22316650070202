import classNames from 'classnames';
import { forwardRef } from 'react';
import { FieldErrors } from 'react-hook-form';
import { NumberFormatBase, NumericFormatProps } from 'react-number-format';

import RedWarningIcon from 'assets/icons/red-warning-icon.svg';

interface NumberInputProps extends NumericFormatProps {
  error?: FieldErrors;
  showIconError?: boolean;
}

export const NumberInput = forwardRef(function Input(
  { error, showIconError = false, ...props }: NumberInputProps,
  ref: any
) {
  return (
    <div className="relative">
      <NumberFormatBase
        getInputRef={ref}
        className={classNames('h-input w-full bg-neutral-100 rounded-input p-4 placeholder:text-black/30 font-medium', {
          '!text-err !bg-errBg border border-err pr-12': error && !!error[props.name as string]?.message,
        })}
        {...props}
        format={(val: string) => val.substring(0, props.maxLength)}
      />
      {showIconError && error && !!error[props.name as string]?.message && (
        <RedWarningIcon className="absolute top-1/2 right-4 transform -translate-y-1/2" />
      )}
    </div>
  );
});
