import { useCallback, useState } from 'react';

export const useDisclosure = (initOpen?: boolean) => {
  const [opened, setOpened] = useState<boolean>(initOpen ?? false);

  const toggle: () => void = useCallback(() => {
    setOpened(!opened);
  }, [opened]);

  const close = () => setOpened(false);
  const open = () => setOpened(true);

  return { opened, toggle, close, open };
};
