import Logo from '@/public/assets/logo_asteeda.svg';

export const Loading = () => {
  return (
    <div className="min-h-screen bg-custom-gradient flex flex-col justify-center items-center gap-4">
      <Logo className="w-[73px] h-[76px]" />
      <span className="text-md font-bold">読み込み中</span>
    </div>
  );
};
