import { InfoIcon } from '@/assets/icons';
import Menu from './Menu';
import {
  inquiryUrl,
  ocrEntranceUrl,
  qrEntranceUrl,
  signInUrl,
  ticketDroppedUrl,
  ticketEntranceUrl,
  topPageUrl,
  userDigitalCertificateDetailUrl,
  userTicketDetailUrl,
  userTicketsUrl,
} from '@/helpers/url.helper';
import { useRouter } from 'next/router';
import { OUTER_URLS } from '@/constants/common';
import Link from 'next/link';
import { Logo } from '@/assets/icons';

const showMenuPaths = [
  ticketDroppedUrl('[uniqueKey]'),
  userTicketsUrl(),
  userTicketDetailUrl('[uniqueKey]'),
  ticketEntranceUrl('[uniqueKey]'),
  signInUrl(),
  inquiryUrl(),
  topPageUrl(),
  ocrEntranceUrl(),
  qrEntranceUrl(),
  userDigitalCertificateDetailUrl('[uniqueKey]'),
];

const showInfoPaths = [topPageUrl()];

const Header = () => {
  const { pathname } = useRouter();

  const isShowMenu = showMenuPaths.includes(pathname);
  const isShowInfo = showInfoPaths.includes(pathname);

  return (
    <div className="flex justify-between">
      {isShowMenu ? <Menu /> : <div className="invisible w-[44px]"></div>}
      <Logo />
      {isShowInfo ? (
        <Link
          href={OUTER_URLS.HOW_TO_USE}
          className="flex items-center justify-center h-[44px] w-[44px] cursor-pointer"
          target="_blank"
          rel="noreferrer"
        >
          <InfoIcon />
        </Link>
      ) : (
        <div className="invisible w-[44px]"></div>
      )}
    </div>
  );
};

export default Header;
