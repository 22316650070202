/* tslint:disable */
/* eslint-disable */
/**
 * Nestjs Auth0 Template API
 * Nestjs Auth0 Template API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



export * from './api/health-api';
export * from './api/v1-admin-api';
export * from './api/v1-authentication-api';
export * from './api/v1-ocr-api';
export * from './api/v1-s3-images-api';

