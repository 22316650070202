import { GA4_CUSTOM_EVENT } from '@/constants/ga4CustomEvent';
import { ga4PushEvent } from '@/utils/ga4';
import { useCallback, useEffect } from 'react';

export function useGAEvent(screenName?: string) {
  const pushGAEvent = (action: string, name: string) => {
    if (!action || !name) return;
    ga4PushEvent(`${action}_${name}`);
  };

  const pushShowScreenGAEvent = useCallback((screenName: string) => {
    if (!screenName) return;
    pushGAEvent(GA4_CUSTOM_EVENT.SHOW_SCREEN, screenName);
  }, []);

  useEffect(() => {
    if (screenName) pushShowScreenGAEvent(screenName);
  }, [pushShowScreenGAEvent, screenName]);

  return { pushGAEvent, pushShowScreenGAEvent };
}
