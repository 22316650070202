import {
  Benefit1Icon,
  Benefit2Icon,
  Benefit3Icon,
  Benefit4Icon,
  Benefit5Icon,
  Benefit6Icon,
  Benefit7Icon,
  Benefit8Icon,
} from '@/assets/icons';
import { images } from '@/assets/images';
import { benefitUrl } from '@/helpers/url.helper';
import { StaticImageData } from 'next/image';
import React from 'react';

export const GENDER = {
  MALE: 'male',
  FEMALE: 'female',
  NO_ANSWER: 'noAnswer',
};

export const GenderOptions = [
  { value: GENDER.MALE, label: '男性' },
  { value: GENDER.FEMALE, label: '女性' },
  { value: GENDER.NO_ANSWER, label: '無回答' },
];

export const STORAGE_KEY = {
  FIRST_LOAD_SESSION: 'firstLoadSession',
};

export const PRIVACY_POLICY_LINK = 'https://www.scsk.jp/privacy.html';
export const OUTER_URLS = {
  PRIVACY: 'https://www.scsk.jp/privacy.html',
  TERM: 'https://drive.google.com/file/d/19R26joGpQMfNBAkT4fDwb2EFy0FW0b0_/view',
  HOW_TO_USE: 'https://sandy-dresser-c92.notion.site/for-web-f6ec770bbaf34a40b0b5dd8d40a49f2f',
  FAQ: 'https://sandy-dresser-c92.notion.site/for-web-926e9bb3ad674185a31e306ef740d904?pvs=4',
};

export const INQUIRY_EMAIL_ADDRESS = 'nftatokara-support@scsk.jp';

export enum HIGHLIGHT_TYPE {
  YOUTUBE = 'youtube',
  IMAGE = 'image',
}

export const pastVisitOptions = [
  { label: '初めて', value: 1 },
  { label: '2回目', value: 2 },
  { label: '3回目', value: 3 },
  { label: '4回目', value: 4 },
  { label: '5回目以上', value: 5 },
  { label: '10回目以上', value: 10 },
];

export const awarenessOptions = [
  { label: '全く知らない', value: '全く知らない' },
  { label: 'チーム名だけ知っている', value: 'チーム名だけ知っている' },
  { label: '所属選手の名前を一部知っている', value: '所属選手の名前を一部知っている' },
  { label: '所属選手の名前や試合結果までよく知っている', value: '所属選手の名前や試合結果までよく知っている' },
];

export const relationshipOptions = [
  { label: '一人', value: '一人' },
  { label: '友達', value: '友達' },
  { label: '夫婦/カップル', value: '夫婦/カップル' },
  { label: '子連れ', value: '子連れ' },
  { label: 'その他', value: 'その他' },
];

export const preferredTicketTypeOptions = [
  {
    label: '紙',
    options: [
      {
        label: '紙（手元に残せるため）',
        value: '紙（手元に残せるため）',
      },
      {
        label: '紙（使い慣れているため）',
        value: '紙（使い慣れているため）',
      },
      {
        label: '紙（現物の安心感があるため）',
        value: '紙（現物の安心感があるため）',
      },
      {
        label: '紙（譲渡しやすいため）',
        value: '紙（譲渡しやすいため）',
      },
      {
        label: '紙（その他）',
        value: '紙（その他）',
      },
    ],
  },
  {
    label: '電子',
    options: [
      {
        label: '電子（紛失の心配がないため）',
        value: '電子（紛失の心配がないため）',
      },
      {
        label: '電子（持ち運びが便利なため）',
        value: '電子（持ち運びが便利なため）',
      },
      {
        label: '電子（環境にやさしいため）',
        value: '電子（環境にやさしいため）',
      },
      {
        label: '電子（電子ならではのデザイン性があるため）',
        value: '電子（電子ならではのデザイン性があるため）',
      },
      {
        label: '電子（その他）',
        value: '電子（その他）',
      },
    ],
  },
  {
    label: 'どちらでもよい',
    options: [
      {
        label: 'どちらでもよい',
        value: 'どちらでもよい',
      },
    ],
  },
];

export enum BenefitType {
  MATCHDAY_PROGRAM = 'matchday-program',
  RESERVATION_OF_SEATS = 'reservation-of-seats',
  RENTAL_OF_CHEERING_GOODS = 'rental-of-cheering-goods',
  PREDICTION_OF_WIN_OR_LOSE = 'prediction-of-win-or-lose',
  POST_CHEERING_MESSAGE = 'post-cheering-message',
  MVP_VOTING = 'mvp-voting',
  AFTER_REPORT = 'after-report',
  SUGGESTION_BOX = 'suggestion-box',
}

export const BENEFITS: Array<{
  key: BenefitType;
  label: string;
  icon: React.ReactNode;
  href: string;
  className?: string;
  notice?: boolean;
  pageTitle?: string;
  thumbnail?: StaticImageData;
}> = [
  {
    key: BenefitType.MATCHDAY_PROGRAM,
    label: 'マッチデープログラム',
    icon: Benefit1Icon,
    href: benefitUrl(BenefitType.MATCHDAY_PROGRAM),
    pageTitle: 'マッチデープログラム\nオンライン',
    thumbnail: images.Matchday,
  },
  {
    key: BenefitType.RESERVATION_OF_SEATS,
    label: '観戦シート予約',
    icon: Benefit2Icon,
    href: benefitUrl(BenefitType.RESERVATION_OF_SEATS),
    pageTitle: '観戦シート予約',
  },
  {
    key: BenefitType.RENTAL_OF_CHEERING_GOODS,
    label: '応援グッズレンタル',
    icon: Benefit3Icon,
    href: benefitUrl(BenefitType.RENTAL_OF_CHEERING_GOODS),
    pageTitle: '応援グッズレンタル',
  },
  {
    key: BenefitType.PREDICTION_OF_WIN_OR_LOSE,
    label: '勝敗予想',
    icon: Benefit4Icon,
    href: benefitUrl(BenefitType.PREDICTION_OF_WIN_OR_LOSE),
    pageTitle: '勝敗予想',
    thumbnail: images.WinLose,
  },
  {
    key: BenefitType.POST_CHEERING_MESSAGE,
    label: '応援メッセージ投稿',
    icon: Benefit5Icon,
    href: benefitUrl(BenefitType.POST_CHEERING_MESSAGE),
    pageTitle: '応援メッセージ投稿',
  },
  {
    key: BenefitType.MVP_VOTING,
    label: 'MVP投票',
    icon: Benefit6Icon,
    href: benefitUrl(BenefitType.MVP_VOTING),
    pageTitle: 'MVP投票',
  },
  {
    key: BenefitType.AFTER_REPORT,
    label: 'アフターレポート',
    icon: Benefit7Icon,
    href: benefitUrl(BenefitType.AFTER_REPORT),
    pageTitle: 'アフターレポート',
  },
  {
    key: BenefitType.SUGGESTION_BOX,
    label: 'ご意見ボックス',
    icon: Benefit8Icon,
    href: benefitUrl(BenefitType.SUGGESTION_BOX),
    pageTitle: 'ご意見ボックス',
  },
];
