import { createPortal } from 'react-dom';
import { CloseIcon } from '@/assets/icons';

type ModalProps = {
  title?: string;
  onClose: () => void;
  children: React.ReactNode;
};

export function Modal({ title, onClose, children }: ModalProps) {
  return createPortal(
    <div className="z-50 drawer fixed top-0 left-0 w-screen h-screen bg-black1/40 flex justify-center items-center">
      <div className="flex flex-col h-full max-h-screen max-w-[393px] w-full p-4">
        <div className="max-h-full relative bg-white rounded-xl p-4 pr-2 flex flex-col gap-4">
          <div
            className="primary-color-icon absolute top-4 right-4 cursor-pointer flex h-[44px] w-[44px] items-center justify-center"
            onClick={onClose}
          >
            <CloseIcon />
          </div>
          {title && (
            <div className="min-h-[44px] flex items-center pr-2">
              <h2 className="text-semi-2xl font-bold max-w-[calc(100%-32px)] truncate">{title}</h2>
            </div>
          )}
          <div className="w-full max-h-[calc(100dvh-32px-16px-44px)] overflow-auto pr-2">{children}</div>
        </div>
      </div>
    </div>,
    document.getElementsByTagName('main')[0]
  );
}
