import { CloseIcon } from 'assets/icons';
import { createPortal } from 'react-dom';

interface OverlayProps {
  children: React.ReactNode;
  onClose: () => void;
}

export default function Overlay({ children, onClose }: OverlayProps) {
  return createPortal(
    <div className="drawer fixed z-50 top-0 left-0 w-screen h-screen bg-checkboxFillColor flex justify-center">
      <div className="relative flex flex-col h-full max-w-[393px] w-full p-4">
        <div
          className="absolute top-4 left-4 cursor-pointer flex h-[44px] w-[44px] items-center justify-center"
          onClick={onClose}
        >
          <CloseIcon />
        </div>
        {children}
      </div>
    </div>,
    document.getElementsByTagName('main')[0]
  );
}
