import classNames from 'classnames';
import { InputHTMLAttributes, forwardRef } from 'react';
import { FieldErrors } from 'react-hook-form';

import RedWarningIcon from 'assets/icons/red-warning-icon.svg';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: FieldErrors;
  showIconError?: boolean;
}

export const Input = forwardRef(function Input({ error, showIconError = false, ...props }: InputProps, ref: any) {
  return (
    <div className="relative">
      <input
        ref={ref}
        className={classNames('h-input w-full bg-neutral-100 rounded-input p-4 placeholder:text-black/30 font-medium', {
          '!text-err !bg-errBg border border-err !pr-12': error && !!error[props.name as string]?.message,
        })}
        {...props}
      />
      {showIconError && error && !!error[props.name as string]?.message && (
        <RedWarningIcon className="absolute top-1/2 right-4 transform -translate-y-1/2" />
      )}
    </div>
  );
});
