import classNames from 'classnames';
import { InputHTMLAttributes, forwardRef } from 'react';
import { FieldErrors } from 'react-hook-form';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: FieldErrors;
}

export const InputRadio = forwardRef(function InputRadio({ label, error, ...props }: InputProps, ref: any) {
  return (
    <label className="flex items-center gap-2">
      <input
        ref={ref}
        type="radio"
        {...props}
        className={classNames(
          "peer relative h-6 w-6 appearance-none rounded-full border-2 border-solid border-radio after:absolute after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-checkboxFillColor checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[14px] checked:after:w-[14px] checked:after:bg-checkboxFillColor checked:after:rounded-full checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer ",
          {
            '!border-err !bg-errBg': error && !!error[props.name as string]?.message,
          }
        )}
      />
      <span className="text-[#4D4D4D] peer-checked:!text-checkboxFillColor peer-checked:font-bold">{label}</span>
    </label>
  );
});
