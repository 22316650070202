import { createPortal } from 'react-dom';

interface DrawerProps {
  children: React.ReactNode;
  hasMask?: boolean;
}

export function Drawer({ children, hasMask = false }: DrawerProps) {
  if (hasMask) {
    return createPortal(
      <div className="drawer fixed inset-0 bg-black/85 z-50 duration-100">
        <div className="bottom-drawer fixed bottom-0 w-full bg-checkboxFillColor px-6 pt-8 pb-[50px] rounded-t-wrapper duration-200 ease-in-out">
          {children}
        </div>
      </div>,
      document.getElementsByTagName('main')[0]
    );
  }
  return createPortal(
    <div className="bottom-drawer fixed bottom-0 w-full z-50 rounded-t-wrapper bg-checkboxFillColor px-6 pt-8 pb-[50px]">
      {children}
    </div>,
    document.getElementsByTagName('main')[0]
  );
}
