import classNames from 'classnames';
import ConsumedSign from '@/assets/images/consumed-sign.svg';

interface TicketProps {
  thumbnail: string;
  name: string;
  eventPlaceName: string;
  eventStartTime: string;
  isUsed?: boolean;
  isExpired?: boolean;
  isFullContent?: boolean;
}

export function Ticket({
  thumbnail,
  name,
  eventPlaceName,
  eventStartTime,
  isUsed = false,
  isExpired = false,
  isFullContent = false,
}: TicketProps) {
  return (
    <div className="relative w-full">
      <div className="shadow-bx-shadow w-full h-fit rounded-t-[20px] overflow-hidden clip-path-bottom bg-gray1">
        <img
          className={classNames('w-full h-full object-cover aspect-square text-transparent', {
            invisible: !thumbnail,
          })}
          src={thumbnail}
          alt="ticket thumbnail"
        />
      </div>
      <div className="flex w-full">
        <div className="h-[78px] min-w-[39px] bg-scoop-left">
          <div className="h-full w-full rounded-r-full shadow"></div>
        </div>
        <div className="flex-grow bg-white">
          {!isUsed && <div className="w-full h-1/2 border-b-2 border-gray1 border-dashed"></div>}
        </div>
        <div className="h-[78px] min-w-[39px] bg-scoop-right">
          <div className="h-full w-full rounded-l-full shadow"></div>
        </div>
      </div>
      <div className="relative shadow-bx-shadow flex flex-col gap-2.5 p-6 bg-white rounded-b-[20px] clip-path-top">
        <h2
          className={classNames('text-xl text-primaryRed font-bold', {
            '!text-gray1': isUsed || isExpired,
            'line-clamp-2': !isFullContent,
          })}
          title={name}
        >
          {name}
        </h2>
        <div className="text-gray1 font-medium">
          <span
            className={classNames('text-sm', {
              'line-clamp-2': !isFullContent,
            })}
            title={eventPlaceName}
          >
            {eventPlaceName}
          </span>
          <p className="text-xl whitespace-nowrap max-text-break:whitespace-pre-line">{eventStartTime}</p>
        </div>
        {isUsed && (
          <div className="absolute w-fit h-fit top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <ConsumedSign />
          </div>
        )}
      </div>
    </div>
  );
}
