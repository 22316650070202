import { graphqlApiClient } from '@/apis/GraphqlApiClient';
import {
  CreateUserCertificateDocument,
  CreateUserCertificateMutation,
  CreateUserCertificateMutationVariables,
  UserCertificateEntity,
  UserDigitalCertificatesDocument,
  UserDigitalCertificatesQuery,
  UserDigitalCertificatesQueryVariables,
  UserGetDigitalCertificateDocument,
  UserGetDigitalCertificateQuery,
  UserGetDigitalCertificateQueryVariables,
} from '@/generated/graphql';

const findAll = async (variables: UserDigitalCertificatesQueryVariables): Promise<UserCertificateEntity[]> => {
  const userDigitalCertificatesQuery = await graphqlApiClient().request<UserDigitalCertificatesQuery>(
    UserDigitalCertificatesDocument,
    variables
  );

  return userDigitalCertificatesQuery.userGetAllDigitalCertificates as UserCertificateEntity[];
};

const findOne = async (variables: UserGetDigitalCertificateQueryVariables): Promise<UserCertificateEntity> => {
  const userDigitalCertificateQuery = await graphqlApiClient().request<UserGetDigitalCertificateQuery>(
    UserGetDigitalCertificateDocument,
    variables
  );

  return userDigitalCertificateQuery.userGetDigitalCertificateDetail as UserCertificateEntity;
};

const create = async (variables: CreateUserCertificateMutationVariables): Promise<UserCertificateEntity> => {
  const createUserDigitalCertificateMutation = await graphqlApiClient().request<CreateUserCertificateMutation>(
    CreateUserCertificateDocument,
    variables
  );

  return createUserDigitalCertificateMutation.createUserCertificate as UserCertificateEntity;
};

const getFromBrowser = async (): Promise<string | null> => {
  const userCertificateUniqueKeyCreated: string | null = await localStorage.getItem(
    'nftticket.userCertificateUniqueKeyCreated'
  );
  if (userCertificateUniqueKeyCreated) {
    return userCertificateUniqueKeyCreated;
  } else {
    return null;
  }
};

const saveToBrowser = async (userCertificateUniqueKeyCreated: string) => {
  try {
    localStorage.setItem('nftticket.userCertificateUniqueKeyCreated', userCertificateUniqueKeyCreated);
    window.dispatchEvent(new Event('storage'));
  } catch (e) {
    throw e;
  }
};

const removeFromBrowser = async () => {
  try {
    await localStorage.removeItem('nftticket.userCertificateUniqueKeyCreated');
    window.dispatchEvent(new Event('storage'));
  } catch (e) {
    throw e;
  }
};

export const UserDigitalCertificateRepository = {
  findAll,
  findOne,
  create,
  getFromBrowser,
  saveToBrowser,
  removeFromBrowser,
};
