import { FieldErrors } from 'react-hook-form';
import { getFormErrMessage } from '@/utils/common';

interface FormItemProps {
  name: string;
  children: React.ReactNode;
  label?: string;
  helpText?: string;
  error?: FieldErrors;
  isRequired?: boolean;
}

export function FormItem({ name, children, label, helpText, error, isRequired = false }: FormItemProps) {
  const errorText = error && (getFormErrMessage(name, error) as string);
  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col">
        <div className="flex items-center gap-2">
          {!!label && <label className="text-sm font-bold whitespace-pre-line">{label}</label>}
          {isRequired && <span className="text-primaryRed text-xs font-bold whitespace-nowrap">必須</span>}
        </div>
        {helpText && <p className="text-xs">{helpText}</p>}
      </div>
      {children}
      {!!errorText && <p className="text-err font-bold text-xs">{errorText}</p>}
    </div>
  );
}
