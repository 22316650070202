import classNames from 'classnames';
import Select, { components, DropdownIndicatorProps, Props } from 'react-select';
import { FieldErrors } from 'react-hook-form';

import TriangleDown from 'assets/icons/triangle-down-icon.svg';

interface SelectProps extends Props {
  error?: FieldErrors;
  minimizeIndicator?: boolean;
}

const DropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...props}>
      <TriangleDown />
    </components.DropdownIndicator>
  );
};

export function ReactSelect({ error, minimizeIndicator = false, ...props }: SelectProps) {
  return (
    <Select
      components={{ DropdownIndicator }}
      classNames={{
        container: () => classNames('w-full'),
        control: () =>
          classNames('w-full h-14 !bg-[#F5F5F5] border border-gray1 !rounded-[10px]', {
            '!border-err !bg-errBg': error && !!error[props.name as string]?.message,
          }),
        valueContainer: () =>
          classNames('!py-3 !pl-[14px] !pr-0 !text-black/30', {
            '!pl-[9px]': minimizeIndicator,
          }),
        input: () => classNames('text-back/30 font-medium'),
        indicatorSeparator: () => classNames('!hidden'),
        indicatorsContainer: () =>
          classNames('p-2', {
            'error-container': error && !!error[props.name as string]?.message,
            '!p-0': minimizeIndicator,
          }),
        dropdownIndicator: () => classNames('', { '!p-0 !pr-1': minimizeIndicator }),
        menu: () => classNames('!overflow-hidden !rounded-[10px] !border !border-gray1 !bg-white'),
      }}
      placeholder=""
      isSearchable={false}
      {...props}
    />
  );
}
