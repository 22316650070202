export const GA4_CUSTOM_EVENT = {
  SHOW_SCREEN: 'ShowScreen',
  USER_UNIQUE_KEY: 'UserUniqueKey',
};

export const GA4_SCREEN_NAME = {
  GET_TICKET_ENTRANCE_SCREEN: 'GetTicketEntranceScreen',
  WEB_3_AUTH_CALLBACK: 'Web3AuthCallback',
  LOGIN_SCREEN: 'LoginScreen',
  USER_REGISTRATION_FORM_SCREEN: 'UserRegistrationFormScreen',
  USER_TICKET_QUESTIONNAIRE_FORM_SCREEN: 'UserTicketQuestionnaireFormScreen',
  USER_TICKET_DROPPING_SCREEN: 'UserTicketDroppingScreen',
  TICKET_DROPPED_SCREEN: 'TicketDroppedScreen',
  TICKETS_SCREEN_EXISTS: 'TicketsScreen(exists)',
  TICKET_SHOW_SCREEN_NOT_CONSUMABLE: 'TicketShowScreen(Not consumable)',
  TICKET_SHOW_SCREEN_CONSUMABLE: 'TicketShowScreen(Consumable)',
  TICKETS_SCREEN_BLANK: 'TicketsScreen(blank)',
  TICKET_SHOW_SCREEN_CONSUMED: 'TicketShowScreen(Consumed)',
  TICKET_SHOW_SCREEN_EXPIRED: 'TicketShowScreen(expired)',
  TICKET_CONSUME_SCREEN: 'TicketConsumeScreen',
  POPUP_MENU_SCREEN: 'Popup Menu Screen',
  PWA_INSTALL_MODAL_IOS: 'PWAInstallModal(iOS)',
  PWA_INSTALL_MODAL_ANDROID: 'PWAInstallModal(Android)',
  PAST_TICKET_SCREEN: 'PastTicketScreen',
  PAST_TICKET_HIGHLIGHT_SCREEN: 'PastTicketHighlightScreen',
  PAST_TICKET_HIGHLIGHT_SCREEN_BLANK: 'PastTicketHighlightScreen(blank)',
  OCR_SCAN_SCREEN: 'OCRScanScreen',
  OCR_ENTRANCE_SCREEN: 'OCREntranceScreen',
  STEP2_LOGIN_SCREEN: 'Step2LoginScreen',
  TOP_PAGE_SCREEN: 'TopPageScreen',
  USER_REGISTRATION_SUCCESSFUL_SCREEN: 'UserRegistrationSuccessfulScreen',
  OCR_USER_SURVEY_SCREEN: 'OCRUserSurveyScreen',
  DIGITAL_CERTIFICATES_SCREEN_EXISTS: 'DigitalCertificatesScreen(exists)',
  DIGITAL_CERTIFICATES_SCREEN_BLANK: 'DigitalCertificateShowScreen(blank)',
  QR_SCAN_SCREEN: 'QRScanScreen',
  QR_ENTRANCE_SCREEN: 'QREntranceScreen',
  USER_DIGITAL_CERTIFICATE_DROPPING_SCREEN: 'UserDigitalCertificateDroppingScreen',
  USER_DIGITAL_CERTIFICATE_DROPPED_SCREEN: 'UserDigitalCertificateDroppedScreen',
  PAST_DIGITAL_CERTIFICATE_SCREEN: 'PastDigitalCertificateScreen',
  DIGITAL_CERTIFICATE_SCREEN: 'DigitalCertificateScreen',
  POPUP_SCAN_OCR_TIP_SCREEN: 'PopupScanOcrTipScreen',
  RECEIPT_OCR_ANALYZING_SCREEN: 'ReceiptOCRAnalyzingScreen',
};
